import React, { useEffect } from 'react';
import { Layout, Row, Typography, Spin, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import LoginSidebar from '../../molecules/Login/Sidebar';
import LoginHeader from '../../molecules/Login/Header';
import { isLoggedIn } from '../../../routing/config/utils';

const { Title, Text, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const history = useHistory();
  const auth = isLoggedIn();

  useEffect(() => {
    if (auth) {
      history.push('/dashboard');
    }
  }, []);
  return (
    <Spin indicator={antIcon} size="large" spinning={props.load}>
      <Layout className="login-layout">
        <LoginHeader />
        <Row align="middle" justify="center">
          <Col span={16}>
            <Row align="middle" justify="center">
              <Col flex="0 0 420px" style={{ overflow: 'hidden', padding: '0 10px' }}>
                {props.children}
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <LoginSidebar />
          </Col>
        </Row>
        <div style={{ position: 'absolute', left: 20, bottom: 20 }}>2.3.0</div>
      </Layout>
    </Spin>
  );
};
