import React, { useState } from 'react';
import { Button, Row, Col, Typography, Image, DatePicker, Spin, Card, Form, Radio, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import crossIcon from '../../../assets/svg/cross-icon.svg';
import addManually from '../../../assets/svg/add-manually.svg';
import uploadCSVIcon from '../../../assets/svg/uploadCSV.svg';
import downloadCSV from '../../../assets/svg/download-csv.svg';
import uploadIcon from '../../../assets/svg/upload.svg';
import checkIcon from '../../../assets/svg/check-icon.svg';
import uploadFileIcon from '../../../assets/svg/upload-file-icon.svg';
import trashIcon from '../../../assets/svg/icon-trash.svg';
import { baseUrl } from '../../../configs/constants';
import { BULK_IMPORT_CONTACT_CSV } from '../../../configs/endpoints';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UploadField } from '../../atoms/FormElement';
import { exportContact } from '../../modules/Dashboard/Contacts/ducks/actions';
import { addObject } from '../../../services/crudApis';

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { onClose, title, paragraph, manualLink, afterUploadLink } = props;
  const token = JSON.parse(localStorage.getItem('token'));
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, errors, clearErrors, handleSubmit, setValue } = useForm({
    defaultValues: {
      accounts_committee: [{ name: 'useFieldArray' }],
    },
  });
  const [loading, setLoading] = useState(false);
  const [uploadCSV, setUploadCSV] = useState(false);
  const [uploadType, setUploadType] = useState('');
  const [fileUpload, setFileUpload] = useState(null);
  const [fileList, setFileList] = useState([]);

  const closePopUp = () => {
    setUploadCSV(false);
    setUploadType(null);
    setFileUpload(null);
    onClose();
  };

  const onTypeSelect = (e) => {
    setUploadType(e.target.value);
  };

  const draggerProps = {
    name: 'file',
    multiple: false,
    accept: '.csv',
    maxCount: 1,

    onChange(info) {
      const { status } = info.file;
      setFileList(info.fileList);
      clearErrors('uploadCSV');

      if (status === 'done') {
        dispatch(exportContact(info.file.response));
      }
    },

    onRemove(e) {
      setFileUpload(null);
    },
  };

  const onFinish = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', fileUpload);
    addObject(BULK_IMPORT_CONTACT_CSV, formData)
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          history.push(afterUploadLink);
        }, 2000);
      })
      .catch(() => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const continuePopup = () => {
    if (uploadType === 'upload') {
      setUploadCSV(true);
    } else {
      history.push(manualLink);
    }
  };

  const removeFile = () => {
    setFileUpload(null);
    setFileList([]);
  };

  const handleFileChange = (e) => {
    setFileUpload(e);
    const isCSV = e.type === 'text/csv';
    if (!isCSV) {
      setFileUpload(null);
      message.error(`${e.name} is not a CSV file`);
    }
    return isCSV || Upload.LIST_IGNORE;
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row justify="space-between" align="middle" className="new_popup">
        <Col span={24}>
          <Row justify="space-between" align="middle" className="popup_header">
            <Col>
              <Title level={4} className="mb-0">
                {title}
              </Title>
            </Col>
            <Col className="popup_close_btn">
              <Button size="large" type="link" className="p-0" onClick={closePopUp}>
                <Image src={crossIcon} preview={false} size={52} />
              </Button>
            </Col>
          </Row>
        </Col>

        <Form layout="vertical" name="normal_login" className="upload_form" onFinish={handleSubmit(onFinish)}>
            <Col span={24}>
              <Title level={4}>Upload CSV with Contacts</Title>
            </Col>
            <Col span={24}>
              <Paragraph className="font_16">
                The CSV file must follow the specific format.
                <br /> Use the sample file below to prepare the contacts for importation.
              </Paragraph>
            </Col>
            <Col span={24}>
              <div className="button_icon">
                <a
                  href="https://app.electafile.com/assets/csv/Contact_Import_Sample.csv"
                  target="_blank"
                  rel="noreferrer"
                  download
                  className="download_csv"
                >
                  <Image src={downloadCSV} preview={false} />
                  <span className="download_text">DOWNLOAD CSV SAMPLE</span>
                </a>
              </div>
            </Col>
            <Col span={24} className="upload_csv">
              <UploadField
                fieldname="uploadCSV"
                label=""
                control={control}
                dummyRequest=""
                initValue=""
                draggerProps={draggerProps}
                image={uploadIcon}
                content="Upload CSV FILE WITH Contacts"
                iProps={{
                  size: 'large',
                  customRequest: () => {},
                  beforeUpload: handleFileChange,
                  fileList: fileList,
                }}
              />
              {fileUpload?.name && (
                <div className="upload_file_info">
                  <Image src={uploadFileIcon} preview={false} width={52} />
                  <div className="file_name">{fileUpload?.name}</div>
                  <Button type="text" onClick={removeFile} className="remove_icon">
                    <Image src={trashIcon} preview={false} width={22} />
                  </Button>
                </div>
              )}
            </Col>
            <Col span={24}>
              <div className="new_btn_group">
                <Row justify="center" gutter={[20, 20]}>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="button"
                      className="font-gilroy white-btn"
                      size="large"
                      onClick={closePopUp}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="secondary"
                      htmlType="submit"
                      className="font-gilroy bg-red"
                      size="large"
                      disabled={!fileUpload}
                    >
                      Continue
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Form>
      </Row>
    </Spin>
  );
};
