import React, { useState, useEffect, Fragment } from 'react';
import {
  Card,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Menu,
  Dropdown,
  Input,
  Image,
  message,
  Tooltip,
  Select,
  Slider,
  InputNumber,
  Tag,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { MoreOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import plusIcon from '../../../../../../assets/svg/plus-icon-white.svg';
import exportCSV from '../../../../../../assets/svg/export-csv.svg';
import arrowIcon from '../../../../../../assets/svg/select-icon.svg';
import clearIcon from '../../../../../../assets/svg/clear-filters.svg';
import { clearDebtsFiltersTo, getobligationByListingTO, setFiltersOptionsDebtsTo } from '../../ducks/actions';
import { deleteDebts, deleteMultipleDebts } from '../../ducks/services';
import { Popup } from '../../../../../atoms/Popup';
import ConfirmDelete from '../../../../../molecules/ConfirmDelete';
import ReportConfirmDeletePopup from '../../../../../molecules/ReportConfirmDeletePopup';
import moment from 'moment';
import { DOWNLOAD_DEBTS_CSV } from '../../../../../../configs/endpoints';
import ExportPeriod from '../../../../../molecules/ExportPeriod';
import Dashboard from '../../../../../pages/Dashboard';
import ArrowIconNew from '../../../../../atoms/CustomIcons/ArrowIconNew';
import { addObject } from '../../../../../../services/crudApis';
import AmountDropdown from '../../../../../molecules/AmountDropdown';

const { Text } = Typography;

export default (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteMultiple, setConfirmDeleteMultiple] = useState(false);
  const [confirmReport, setConfirmReport] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [confirmReportMultiple, setConfirmReportMultiple] = useState(false);
  const [receiptReportID, setReceiptReportID] = useState('');
  const [deleteRecordID, setDeleteRecordID] = useState();
  const [multipleReportID, setMultipleReportID] = useState([]);
  const [periodPDF, setPeriodPDF] = useState(false);
  const [inputValue, setInputValue] = useState([]);
  const [amountRange, setAmountRange] = useState([]);
  const [filterRange, setFilterRange] = useState();
  const [highestAmount, setHighestAmount] = useState(0);
  const [rangeBox, setRangeBox] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [valueTo, setValueTo] = useState('');
  const [tableRecord, setTableRecord] = useState();
  const [statusValue, setStatusValue] = useState([]);
  const obligationToListingData = useSelector((state) => state.debts.obligationToListing);
  const selectedFilters = useSelector((state) => state.debts.filterDebtsTo);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    const contactData = [...obligationToListingData];
    const currValue = selectedFilters?.searchDebts?.toLowerCase();
    let filteredData = [];
    setValueTo(currValue);

    filteredData = contactData.filter((entry) => {
      const amount = +entry?.remaining?.replace('$', '').split(',').join('');

      const filterStatus =
        selectedFilters?.status?.length > 0
          ? selectedFilters?.status?.some((res) => (res === 'paid' ? entry?.isPaid : !entry?.isPaid))
          : true;

      const typedData = currValue
        ? (entry?.creditor && entry?.creditor.toLowerCase().includes(currValue)) ||
          (entry?.remaining && entry?.remaining.includes(currValue)) ||
          (entry?.start_date && entry?.start_date.includes(currValue)) ||
          (entry?.total_incurred && entry?.total_incurred.includes(currValue)) ||
          (entry?.beginning_balance && entry?.beginning_balance.includes(currValue))
        : //(currValue?.includes('paid') && entry?.total_paid.toLowerCase())
          true;

      const amountData =
        selectedFilters?.remainingBalance?.length > 0 ? amount >= inputValue[0] && amount <= inputValue[1] : true;

      return typedData && amountData && filterStatus;
    });
    setDataSource(filteredData);
  }, [selectedFilters]);

  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    dispatch(clearDebtsFiltersTo());
    dispatch(getobligationByListingTO());
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button onClick={() => editRecord(tableRecord)} type="link" className="btn-link">
          Edit
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button onClick={() => deleteRecord(tableRecord)} type="link" className="btn-link">
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  );
  const listColumns = [
    {
      title: 'Debtor',
      dataIndex: 'creditor',
      sorter: (a, b) => a.creditor.localeCompare(b.creditor),
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      width: 120,
      sorter: (a, b) =>
        +moment(a.start_date, 'MM-DD-YYYY').format('X') - +moment(b.start_date, 'MM-DD-YYYY').format('X'),
    },
    {
      title: 'Beginning Balance',
      dataIndex: 'beginning_balance',
      sorter: (a, b) =>
        +a.beginning_balance.replace('$', '').split(',').join('') -
        +b.beginning_balance.replace('$', '').split(',').join(''),
    },
    {
      title: 'Beginning Balance',
      dataIndex: 'beginning_balance',
      sorter: (a, b) =>
        +a.beginning_balance.replace('$', '').split(',').join('') -
        +b.beginning_balance.replace('$', '').split(',').join(''),
    },
    {
      title: 'Total Amount Incurred',
      dataIndex: 'total_incurred',
      sorter: (a, b) =>
        +a.total_incurred.replace('$', '').split(',').join('') - +b.total_incurred.replace('$', '').split(',').join(''),
    },
    {
      title: 'Total Amount Paid',
      dataIndex: 'total_paid',
      sorter: (a, b) =>
        +a.total_paid.replace('$', '').split(',').join('') - +b.total_paid.replace('$', '').split(',').join(''),
    },
    {
      title: 'Remaining Balance',
      dataIndex: 'remaining',
      sorter: (a, b) =>
        +a.remaining.replace('$', '').split(',').join('') - +b.remaining.replace('$', '').split(',').join(''),
    },
    {
      title: 'Status',
      dataIndex: 'bankaccount',
      sorter: (a, b) => a.bankaccount.localeCompare(b.bankaccount),
      render: (a, b) => {
        let clname = '';
        let statusText = '';
        if (b?.isPaid) {
          clname = 'accepted';
          statusText = 'PAID';
        } else {
          clname = 'draft';
          statusText = 'UNPAID';
        }
        return <Tag className={`defaultTag ${clname}`}>{statusText}</Tag>;
      },
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" onClick={() => handleButtonClick(record)}>
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleButtonClick = (e) => {
    setTableRecord(e);
  };

  const editRecord = (record) => {
    history.push({
      pathname: `/edit-debt/${record?.id}`,
      state: {
        expenditureType: 'debt_owed_to_the_committee',
      },
    });
  };

  const deletePopup = {
    closable: false,
    visibility: confirmDelete,
    class: 'black-modal',
    content: (
      <ConfirmDelete
        title="Delete Debt"
        paragraph="Are you sure you want to delete this Debt?"
        onClose={() => setConfirmDelete(false)}
        onDelete={() => onDelete()}
        updateApi={() => (confirmDeleteMultiple ? updateApiMultiple() : updateApi())}
      />
    ),
    width: 450,
    onCancel: () => setConfirmDelete(false),
  };

  const deleteReport = {
    closable: false,
    visibility: confirmReport,
    class: 'black-modal',
    content: (
      <ReportConfirmDeletePopup
        title="Confirm Report Amendments"
        paragraph="Are you sure you want to delete this contact?"
        onClose={() => setConfirmReport(false)}
        onDelete={() => onDelete()}
        responseData={responseData}
        updateApi={() => (confirmReportMultiple ? updateReportApiMultiple() : updateReportApi())}
      />
    ),
    width: 542,
    onCancel: () => setConfirmReport(false),
  };

  const updateApi = () => {
    setLoading(true);
    deleteDebts(deleteRecordID)
      .then((response) => {
        message.success('Debt deleted.');
        dispatch(getobligationByListingTO());
        setConfirmDelete(false);
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmDelete(false);
        message.error('Something went wrong');
      });
  };

  const updateApiMultiple = () => {
    setLoading(true);
    let debtsToBeDeleted = [];
    for (const keys of selectedRowKeys) {
      let exp = obligationToListingData.find((x) => x.key === keys);
      if (exp && exp.id) {
        debtsToBeDeleted.push(exp.id);
      }
    }

    deleteMultipleDebts(debtsToBeDeleted)
      .then((response) => {
        message.success('Debts deleted.');
        dispatch(getobligationByListingTO());
        setConfirmDelete(false);
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmDelete(false);
        message.error('Something went wrong');
      });
  };

  const updateReportApi = () => {
    setLoading(true);
    deleteDebts(deleteRecordID)
      .then((response) => {
        message.success('Debt deleted.');
        dispatch(getobligationByListingTO());
        setConfirmReport(false);
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmReport(false);
        message.error('Something went wrong');
      });
  };

  const updateReportApiMultiple = () => {
    let expenditureToBeDeleted = [];
    for (const keys of selectedRowKeys) {
      let exp = obligationToListingData.find((x) => x.key === keys);
      if (exp && exp.id) {
        expenditureToBeDeleted.push(exp.id);
      }
    }
    deleteDebts(expenditureToBeDeleted)
      .then((response) => {
        message.success('Debt deleted.');
        dispatch(getobligationByListingTO());
        setConfirmReport(false);
        setSelectedRowKeys([]);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      })
      .catch((e) => {
        setLoading(false);
        setConfirmReport(false);
        message.error('Something went wrong');
      });
  };

  const deleteRecord = (record) => {
    setDeleteRecordID(record?.id);
    const amendmentPayload = {
      dateEffective: moment(record?.start_date).format('YYYY-MM-DD'),
      reportIds: false,
    };

    addObject('getAmendedReports', amendmentPayload)
      .then((response) => {
        if (response?.data?.length > 0) {
          setResponseData(response?.data);
          setConfirmReport(true);
        } else {
          setConfirmDelete(true);
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const deleteMultiple = () => {
    const oldestDateObject = selectedRows.reduce((oldest, current) => {
      return moment(current.start_date).isBefore(moment(oldest.start_date)) ? current : oldest;
    }, selectedRows[0]);

    const amendmentPayload = {
      dateEffective: moment(oldestDateObject?.start_date).format('YYYY-MM-DD'),
      reportIds: false,
    };

    addObject('getAmendedReports', amendmentPayload)
      .then((response) => {
        if (response?.data?.length > 0) {
          setResponseData(response?.data);
          setConfirmDelete(false);
          setConfirmReport(true);
          setConfirmReportMultiple(true);
        } else {
          setConfirmReport(false);
          setConfirmDelete(true);
          setConfirmDeleteMultiple(true);
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error('Something went wrong');
      });
  };

  const fetchData = (params = {}, fromDate = 'null', toDate = 'null') => {
    setLoading(true);
    fetch(``).then(({ results }) => {
      setLoading(false);
      setPagination({
        ...params.pagination,
      });
    });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  const handleTableChange = (newPagination, filters) => {
    fetchData({
      pagination: newPagination,
      ...filters,
    });
  };

  const onClickRow = (record) => {
    return {
      onDoubleClick: () => {
        history.push({
          pathname: `/edit-debt/${record?.id}`,
          state: {
            expenditureType: 'debt_owed_to_the_committee',
          },
        });
      },
    };
  };

  useEffect(() => {
    setDataSource(obligationToListingData);

    if (obligationToListingData && obligationToListingData?.length > 0) {
      const numericalValues = obligationToListingData.map((res) => {
        // Remove the "$" sign and parse the string as an integer
        return parseInt(res?.remaining?.replace('$', '').split(',').join(''));
      });
      const highestValue = Math.max(...numericalValues);
      setInputValue([0, highestValue]);
      setHighestAmount(highestValue);
    } else {
      setHighestAmount(0);
      setInputValue([0, 0]);
    }
  }, [obligationToListingData]);

  const hasSelected = selectedRowKeys.length > 0;

  const applyRange = () => {
    dispatch(setFiltersOptionsDebtsTo('remainingBalance', inputValue));
    setRangeBox(false);
  };

  const clearFilters = () => {
    if (obligationToListingData && obligationToListingData?.length > 0) {
      const numericalValues = obligationToListingData.map((res) => {
        return parseInt(res?.remaining?.replace('$', '').split(',').join(''));
      });
      const highestValue = Math.max(...numericalValues);
      setInputValue([0, highestValue]);
      setHighestAmount(highestValue);
    } else {
      setHighestAmount(0);
      setInputValue([0, 0]);
    }
    setFilterRange(null);
    setAmountRange([]);
    setStatusValue([]);
    setDataSource(obligationToListingData);
    setValueTo('');
  };

  const rangeValue = (value) => {
    setAmountRange(value);
    setInputValue(value);
  };

  const exportPeriod = {
    closable: false,
    visibility: periodPDF,
    class: 'black-modal',
    content: (
      <ExportPeriod
        title="Select a Period For Export"
        paragraph="Select debts start dates period."
        csvEndPoint={DOWNLOAD_DEBTS_CSV}
        type="Debts"
        csvType="TO"
        onClose={() => setPeriodPDF(false)}
      />
    ),
    width: 542,
    onCancel: () => setPeriodPDF(false),
  };

  const amountStart = (value) => {
    setInputValue([value, inputValue[1]]);
    setAmountRange([value, inputValue[1]]);
  };

  const amountEnd = (value) => {
    setInputValue([inputValue[0], value]);
    setAmountRange([inputValue[0], value]);
  };

  const clearAmount = () => {
    if (obligationToListingData && obligationToListingData?.length > 0) {
      const numericalValues = obligationToListingData.map((res) => {
        // Remove the "$" sign and parse the string as an integer
        return parseInt(res?.remaining?.replace('$', '').split(',').join(''));
      });
      const highestValue = Math.max(...numericalValues);
      setInputValue([0, highestValue]);
      setHighestAmount(highestValue);
      setAmountRange([]);
    } else {
      setHighestAmount(0);
      setInputValue([0, 0]);
      setAmountRange([]);
    }
  };

  const locale = {
    emptyText: (
      <div className="empty_table">
        <div className="emprty_text">
          There are no debts owed to the committee.{' '}
          <Button
            onClick={() => history.push('/new-debts/debt_owed_to_the_committee')}
            type="text"
            htmlType="button"
            size="large"
          >
            Add debt.
          </Button>
        </div>
      </div>
    ),
  };

  const handleChange = (value) => {
    setStatusValue(value);
    dispatch(setFiltersOptionsDebtsTo('status', value));
  };

  const reportSelect = [
    { value: 'paid', label: 'Paid' },
    { value: 'unpaid', label: 'Unpaid' },
  ];

  const handleToggle = (e) => {
    e.stopPropagation();
    setRangeBox((prevState) => !prevState);
  };

  const closeDropdown = () => {
    setRangeBox(false);
  };

  return (
    <Dashboard load={loading} heading="Debts">
      <div className="owed_btns">
        <div className="owed_wrapper">
          <Button onClick={() => history.push('/debts/owed-by-the-comittee')} className="font-manrope">
            Owed By the Committee
          </Button>
          <Button onClick={() => history.push('/debts/owed-to-the-comittee')} className="active font-manrope">
            Owed To the Committee
          </Button>
        </div>
      </div>
      <Card className="white-card w-100 pcard-0">
        <Row justify="space-between" align="middle" className="reports_head" gutter={[10, 10]}>
          <Col>
            {hasSelected ? (
              <Button onClick={() => deleteMultiple()} type="text" htmlType="button" className="font-gilroy c-blue">
                Delete Selected{' '}
                <Text className="font-14" style={{ marginLeft: 20 }}>
                  {selectedRowKeys.length} Selected{' '}
                </Text>
              </Button>
            ) : (
              <Button
                onClick={() => history.push('/new-debts/debt_owed_to_the_committee')}
                type="primary"
                htmlType="button"
                className="w-100 font-gilroy"
                size="large"
              >
                <Image src={plusIcon} preview={false} width={22} />
                Add Debt
              </Button>
            )}
          </Col>
          <Col>
            <Row align="middle" gutter={[10, 10]}>
              {!hasSelected && (
                <Col>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Col className="search_box">
                      <Input
                        placeholder="Search..."
                        value={valueTo}
                        onChange={(e) => dispatch(setFiltersOptionsDebtsTo('searchDebts', e.target.value))}
                      />
                    </Col>
                  </div>
                </Col>
              )}
              <Col>
                <Select
                  onChange={handleChange}
                  className="filter_select"
                  placeholder="Status"
                  value={statusValue}
                  mode="multiple"
                  suffixIcon={<ArrowIconNew size={24} />}
                  showArrow
                >
                  {reportSelect &&
                    reportSelect?.map((item, index) => (
                      <Fragment key={index}>
                        <Option value={item?.value}>{item?.label}</Option>
                      </Fragment>
                    ))}
                </Select>
              </Col>
              <Col>
                <Button className={rangeBox ? 'sum_select box_open' : 'sum_select'} onClick={handleToggle}>
                  {amountRange && amountRange?.length > 0 ? (
                    <Tooltip title="Filter: Amount">
                      <div className="r_heading_amount">{`$ ${Number(amountRange[0]).toLocaleString()} - ${Number(
                        amountRange[1],
                      ).toLocaleString()}`}</div>
                    </Tooltip>
                  ) : (
                    <div className="sum_heading">Remaining Balance</div>
                  )}

                  <div className="sum_icon">
                    <img src={arrowIcon} alt="arrowIcon" />
                  </div>
                </Button>
                {rangeBox && (
                  <AmountDropdown
                    clearAmount={clearAmount}
                    rangeValue={rangeValue}
                    amountStart={amountStart}
                    amountEnd={amountEnd}
                    setRangeBox={setRangeBox}
                    setAmountRange={setAmountRange}
                    setInputValue={setInputValue}
                    applyRange={applyRange}
                    inputValue={inputValue}
                    closeDropdown={() => closeDropdown()}
                    highestAmount={highestAmount}
                  />
                )}
              </Col>
              <Col>
                <Button type="ghost" htmlType="button" className="p-0" onClick={clearFilters}>
                  <img src={clearIcon} alt="clearIcon" />
                </Button>
              </Col>
              <Col>
                <Tooltip placement="topLeft" title="Download data in a CSV file.">
                  <Button
                    type="ghost"
                    htmlType="button"
                    className="p-0"
                    style={{ marginLeft: 16 }}
                    onClick={() => setPeriodPDF(true)}
                  >
                    <img src={exportCSV} alt="export CSV" />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        <Table
          rowSelection={rowSelection}
          columns={listColumns}
          dataSource={dataSource}
          pagination={{
            showSizeChanger: dataSource?.length > 10 ? true : false,
            pagination,
          }}
          onChange={handleTableChange}
          onRow={onClickRow}
          className="cursor_table"
          locale={locale}
        />
      </Card>
      <Popup {...deletePopup} />
      <Popup {...deleteReport} />
      <Popup {...exportPeriod} />
    </Dashboard>
  );
};
